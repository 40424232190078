import React, { useState, useContext, useRef, useEffect } from 'react';
import $ from 'jquery';
import facebook from '@img/facebook.webp';
import instagram from '@img/instagram.webp';
import whatsapp from '@img/whatsapp.webp';
import house from '@img/house.webp';
import location from '@img/location.webp';
import heartOff from '@img/heart_off.webp';
import heartOn from '@img/heart_on.webp';
import sendoff from '@img/send-off.webp';
import sendOn from '@img/send-on.webp';
import pencil from '@img/pencil.webp';
import web from '@img/web.webp';
import waze from '@img/waze.webp';
import googlemaps from '@img/googlemaps.webp';
import { changeStoreFavoriteStatus, setComment, internalTracking } from '../api/index';
import UserContext from "../context/useLoginContext";
import useStoreById from '../hooks/useStoreById';
import person from '@img/person.webp';
import { Rating } from 'react-simple-star-rating';
import { getPlaceholder, capitalize } from '../util/anyFunctions';
import ModalResponseToLogin from './ModalResponseToLogin';
import OwlCarousel from 'react-owl-carousel';



import '@styles/ModalDetailStore.scss';

const ModalDetailStore = ({dataStore, categoryValue, setstoreFavorite, setLoading2, setDataStore, setIsdashPage}) => {

    const { user } = useContext(UserContext);
    const [rating, setRating] = useState(0);
    const [ratingComment, setRatingComment] = useState(0);
    const [canSendMessage, setcanSendMessage] = useState(null);
    const [commentvalue, setcommentvalue] = useState('');
    const [textAreaHeight, setTextAreaHeight] = useState("auto");
    const [textAreaHeight2, setTextAreaHeight2] = useState("auto");
    const textareaRef = useRef(null);
    const commentTextRef = useRef(null);
    const commentboxTextRef = useRef(null);
    const endOfModalRef = useRef(null);

    const placeholder_ = getPlaceholder(categoryValue);


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (dataStore && dataStore.usercomments.length > 0) {
                    dataStore.reference = dataStore?.locations.map((item) => {
                        if(item.lat === dataStore?.lat && item.lng === dataStore?.lng){
                            return item.reference
                        }
                    })
                    let count = 0
                    dataStore.usercomments.forEach((element) => {
                        count = count + element.stars
                    });
                    const starsAverage = count / dataStore.usercomments.length;
                    setRating(starsAverage.toFixed(2))
                }else{
                    setRating(0)
                }
            } catch (error) {
                console.error("Error fetching store when user is logged:", error);
            }
        };
        fetchData();
    }, [dataStore]);

    const handleClose = () => {
        $('#response-detail-modal').modal('hide');
    };

    const redirectPage = (link, type) =>{
        const text = `Hola%20te%20encontré%20por%20Firulife%20y%20me%20gustaría%20hacerte%20una%20pregunta`;
        if (type =="whatsapp"){
            link = `https://api.whatsapp.com/send/?phone=51${link}&text=${text}`
        }
        internalTracking({ page: 'search', lat: dataStore.lat, lng: dataStore.lng, storeId: dataStore.id, chanel: type, category:categoryValue })
        window.open(link, '_blank');
    }

    const openNavigator = (lat, long, type) => {
        let navigateUrl
        if (type === 'waze'){
            navigateUrl = `https://www.waze.com/ul?ll=${lat},${long}&navigate=yes`;
        }else{
            navigateUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
        }
        internalTracking({ page: 'search', lat: dataStore.lat, lng: dataStore.lng, storeId: dataStore.id, chanel: type, category:categoryValue })
        window.open(navigateUrl, '_blank');
    };

    const handleClickfavorite = async(storeId) => {
        if(user){
            changeStoreFavoriteStatus(({storeId}))
            dataStore.isFavorite = !dataStore?.isFavorite
            setstoreFavorite({storeId, status: dataStore?.isFavorite});
        }else{
            $('#response-modal-login').modal('show');
        }
    };

    const handleTextareaChange = (event) => {
        setcommentvalue(event.target.value);
        if(event.target.value.length > 0 && event.target.value.length <= 100){
            setcanSendMessage(true)
        }else{
            setTextAreaHeight2(42 + "px");
            const commentText2 = commentTextRef.current;
            if (commentText2) {
                commentText2.style.height = 42 + "px";
            }
            setcanSendMessage(false)
        }
    };

    const autoGrow = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            let height = parseInt(textarea.style.height) + 33;
            textarea.style.height = "auto";
            textarea.style.height = textarea.scrollHeight + "px";
            setTextAreaHeight(height + "px");
            setTextAreaHeight2(height + "px");
            const commentText1 = commentboxTextRef.current;
            if (commentText1) {
                commentText1.style.height = height + "px";
            }
            const commentText2 = commentTextRef.current;
            if (commentText2) {
                commentText2.style.height = height + "px";
            }
        }
    };

    const handleRating = (rate) => {
        if(user){
            setRatingComment(rate)
        }else{
            $('#response-modal-login').modal('show');
        }
    }

    const sendComment = async(storeId) => {
        if(user){
            if (canSendMessage && ratingComment > 0) {
                setIsdashPage && setIsdashPage(false)
                setLoading2(true)
                await setComment({storeId, comment: commentvalue, stars: ratingComment});
                const { store } = await useStoreById(storeId, categoryValue)
                store.lat = dataStore.lat
                store.lng = dataStore.lng
                setRatingComment(0)
                setDataStore(store);
                setcommentvalue('');
                setLoading2(false)
            }
        }else{
            $('#response-modal-login').modal('show');
        }
    };

    const handleClickOpinion = () => {
        endOfModalRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        dataStore?.name ?
            <div className="Response-modal">
                <div className="modal fade" id="response-detail-modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" onClick={handleClose} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="Response-content">
                                    <div className='header'>
                                        <div className='content-img'>
                                            <div className='img'>
                                                <img src={dataStore?.image || placeholder_} alt="firullife-img"/>
                                            </div>
                                        </div>
                                        <div className='content'>
                                            <p className="name">{dataStore?.name}</p>
                                            <p className="description">{dataStore?.description}</p>
                                            <div className="type">
                                                <img src={house} alt="firullife-img"/>
                                                Tipo de establecimiento: {capitalize(dataStore?.type)}
                                            </div>
                                            {dataStore?.type === "físico" && dataStore?.locations.length > 0 && dataStore?.locations.map((item, index) => (
                                                    item.lat === dataStore?.lat && item.lng === dataStore?.lng &&
                                                    <div className="type" key={index}>
                                                        <img src={location} alt="firullife-img"/>
                                                        Dirección: {item.reference}
                                                    </div>
                                                ))}
                                            <div className='content-mix'>
                                                <div className='rating'>
                                                    <span className='numberRating'>{rating}</span>
                                                    <Rating
                                                        className={'rating-stars'}
                                                        initialValue={rating}
                                                        allowFraction
                                                        size={24}
                                                        readonly
                                                        disableFillHover
                                                    />
                                                </div>
                                                <div className='interactive'>
                                                    <div className="type no-border"></div>
                                                    <div className="type" onClick={handleClickOpinion}>
                                                        <img src={pencil} alt="firullife-img"/>
                                                        Opinión
                                                    </div>
                                                    <div className="type no-border" onClick={()=>handleClickfavorite(dataStore?.id)}>
                                                    <img src={dataStore?.isFavorite ? heartOn: heartOff} alt="firullife-img" />
                                                        Favoritos
                                                    </div>
                                                    {/* <div className="type no-border">
                                                        <img src={share} alt="firullife-img"/>
                                                        Compartir
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {dataStore?.categories.length > 0 && (
                                        <div>
                                            <p className="category"><span>Categorías:</span></p>
                                            <div className="item-row">
                                                {dataStore?.categories.map((item, index) => (
                                                    <div className={`item-category ${dataStore?.color}-div`} key={index}>
                                                        {item.name}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {dataStore?.tags.length > 0 && (
                                        <div>
                                            <p className="tags"><span>Servicios y/o características:</span></p>
                                            <div className="item-row">
                                                {dataStore?.tags.map((item, index) => (
                                                    <div className={`item-category ${dataStore?.color}-div`} key={index}>
                                                        {item.name}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {dataStore?.facebookLink || dataStore?.instagramLink ||
                                    dataStore?.whatsapp || dataStore?.web ? (
                                        <div>
                                            <p className="tags"><span>Redes sociales:</span></p>
                                            <div className="item-row">
                                                {
                                                    dataStore?.facebookLink &&
                                                    <div className='socialmedia-detail'  onClick={() => redirectPage(dataStore?.facebookLink, 'facebook')}><img src={facebook} alt="fb-firu"/></div>
                                                }
                                                {
                                                    dataStore?.instagramLink &&
                                                    <div className='socialmedia-detail'  onClick={() => redirectPage(dataStore?.instagramLink, 'instagram')}><img src={instagram} alt="insta-firu"/></div>
                                                }
                                                {
                                                    dataStore?.whatsapp &&
                                                    <div className='socialmedia-detail'  onClick={() => redirectPage(dataStore?.whatsapp,'whatsapp')}><img src={whatsapp} alt="wssp-firu"/></div>
                                                }
                                                {
                                                    dataStore?.web &&
                                                    <div className='socialmedia-detail'  onClick={() => redirectPage(dataStore?.web, 'web')}><img src={web} alt="web-firu"/></div>
                                                }
                                            </div>
                                        </div>
                                    ): ''}
                                    {dataStore?.type === "físico" && dataStore?.locations.length > 1 && (
                                        <div>
                                            <p className="tags"><span>Sucursales:</span></p>
                                            <div className="item-row">
                                                {dataStore?.locations.map((item, index) => (
                                                    item.lat !== dataStore?.lat && item.lng !== dataStore?.lng &&
                                                        <div className='location' key={index}>
                                                            <img src={location} alt="firullife-img"/>
                                                            {item.reference}
                                                        </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {
                                        dataStore?.type === "físico" &&
                                        <>
                                            <p className="tags"><span>Como llegar:</span></p>
                                            <div className="item-row">
                                                <div className='socialmedia-detail-travel'  onClick={() => openNavigator(dataStore?.lat, dataStore?.lng, 'waze')}>
                                                    Waze <img src={waze} alt="waze-firu"/>
                                                </div>
                                                <div className='socialmedia-detail-travel'  onClick={() => openNavigator(dataStore?.lat, dataStore?.lng, 'maps')}>
                                                    Google Maps <img src={googlemaps} alt="maps-firu"/>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        dataStore?.gallery?.length > 0 &&
                                        <>
                                            <p className="tags"><span>Galería:</span></p>
                                            <div className='carrousel-gallery'>
                                                <OwlCarousel className='owl-theme' items={1} loop autoplay>
                                                    {dataStore?.gallery.map((item) => (
                                                        <div className='item' key={item.id}>
                                                            <img src={item.photo} alt="firulife-img" key={item.id} />
                                                        </div>
                                                    ))}
                                                </OwlCarousel>
                                            </div>
                                        </>
                                    }
                                    <div className='comments'>
                                        <div className='title-comments'>
                                            Comentarios <span>{dataStore?.usercomments.length || 0} comentarios</span>
                                        </div>
                                        <div className='list-comments'>
                                                {
                                                    dataStore?.usercomments && dataStore?.usercomments.length === 0 ?
                                                    <div className='item' >Aún no hay comentarios</div>
                                                    :
                                                    dataStore?.usercomments.map((item, index) => (
                                                        <div className='item'  key={index}>
                                                            <div className="img">
                                                                <img src={item.user.image} alt="firulife-img"  key={index} onError={(e) => { e.target.onerror = null; e.target.src = person; }}/>
                                                            </div>
                                                            <div className='content-commet'>
                                                                <div className="content-text">
                                                                    <Rating
                                                                        className={'rating-stars'}
                                                                        initialValue={item.stars}
                                                                        allowFraction
                                                                        size={24}
                                                                        readonly
                                                                        disableFillHover
                                                                    />
                                                                </div>
                                                                <div className="content-text">{item.comment}</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                        </div>
                                        <div className='comment-box'>
                                            <div className='rating' ref={endOfModalRef}>
                                                Calificación:
                                                <Rating
                                                    className={'rating-stars'}
                                                    initialValue={ratingComment}
                                                    ratingValue={ratingComment}
                                                    size={24}
                                                    onClick={handleRating}
                                                />
                                            </div>
                                            <div className='comment-text' ref={commentboxTextRef} style={{ height: textAreaHeight }}>
                                                <div className='img'>
                                                    <img
                                                        src={user ? user.photo : person}
                                                        alt="firulife-user"
                                                        onError={(e) => { e.target.onerror = null; e.target.src = person; }}
                                                    />
                                                </div>
                                                <div className='text-comment' ref={commentTextRef} style={{ height: textAreaHeight2 }}>
                                                    <textarea
                                                        placeholder="Agrega un comentario..."
                                                        rows="1"
                                                        onInput={autoGrow}
                                                        ref={textareaRef}
                                                        onChange={handleTextareaChange}
                                                        value={commentvalue}
                                                        name="textareaCommentStore"
                                                    ></textarea>
                                                    <img src={canSendMessage && ratingComment > 0 ? sendOn: sendoff} alt="firullife-img" onClick={()=>sendComment(dataStore?.id)} />
                                                </div>
                                            </div>
                                            <p className='label-help'>* Soporta maximo 100 caracteres</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalResponseToLogin/>
            </div>
        :
        <div className="Response-modal">
                <div className="modal fade" id="response-detail-modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                            <p className='MessageError'>Cargando</p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default ModalDetailStore;